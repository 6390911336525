import React from "react";

function Footer() {
    return (
        <footer className="footer">
            <div className="content">
                <p className="has-text-centered">
                    Bryan Lopez &copy; 2021
                </p>
            </div>
        </footer>
    );
}

export default Footer;
